import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./content.module.scss"

const Content = props => (
  <div className={styles.content}>
    <ReactMarkdown
      source={props.content}
      transformImageUri={uri =>
        uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
      }
      className="articleContent"
      escapeHtml={false}
    />
  </div>
)

export default Content
